define("discourse/plugins/discourse-mermaid/discourse-markdown/discourse-mermaid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;

  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }

    helper.registerOptions(function (opts, siteSettings) {
      opts.features.mermaid = siteSettings.discourse_mermaid_enabled;
    });
    helper.whiteList(["div.mermaid"]);
    helper.registerPlugin(function (md) {
      md.inline.bbcode.ruler.push("mermaid", {
        tag: "mermaid",
        replace: function replace(state, tagInfo, content) {
          var token = state.push("html_raw", '', 0);
          var escaped = state.md.utils.escapeHtml(content);
          token.content = "<div class=\"mermaid\">\n".concat(escaped, "\n</div>\n");
          return true;
        }
      });
    });
  }
});
define("discourse/plugins/discourse-mermaid/initializers/discourse-mermaid", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "discourse-mermaid",
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.22", function (api) {
        api.decorateCooked(function ($elem) {
          var $mermaid = $elem.find(".mermaid");

          if ($mermaid.length) {
            (0, _loadScript.default)("/plugins/discourse-mermaid/javascripts/mermaid.min.js").then(function () {
              mermaid.init(undefined, $mermaid);
            });
          }
        });
      });
    }
  };
  _exports.default = _default;
});

